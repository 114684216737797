import image1 from './assets/antariksha.png';
import image2 from './assets/theotherkohinoors.png';
import image3 from './assets/antaracyb.png';
import image4 from './assets/eoesandesh.png';
import image5 from './assets/goethe.png';
import image6 from './assets/raji.png';

export const projects = [
    {
        title: 'ANTARIKSHA SANCHAR',
        description: 'Antariksha Studio’s journey of world-building and storytelling through gaming. #PostCyberpunk #VideoGames',
        link: 'https://www.instagram.com/antariksha.studio/',
        image: image1
    },
    {
        title: 'THE OTHER KOHINOORS',
        description: 'A multilingual mixed genre ‘rap’ album of songs that are an ode to the rocks of Hyderabad. #rocksofhyderabad #conservation #documentary',
        link: 'https://www.otherkohinoors.com/',
        image: image2
    },
    {
        title: 'ELSEWHERE IN INDIA',
        description: 'Elsewhere in India is an offshoot of our video game Antara, produced by the Antariksha Studio. #Indofuturism #decolonisation #preservation #heritage #AI',
        link: 'https://www.instagram.com/elsewhereinindia/',
        image: image3
    },
    {
        title: 'ECHOES OF EARTH X SANDESH KADUR',
        description: 'A musical tribute to India’s Natural Wonders. #NationalAnthem #India',
        link: 'https://www.instagram.com/p/CSlRmMFl0Wy/',
        image: image4
    },
    {
        title: 'GOETHE-INSTITUT X M.A.P // A.M.P',
        description: 'A project by Goethe-Institut seeking to capture, document, and explore the intersections of music and activism in South Asia. #Music #Activism #Politics #SouthAsia',
        link: 'https://on.soundcloud.com/Mcys',
        image: image5
    },
    {
        title: 'RAJI: AN ANCIENT EPIC',
        description: 'An action-adventure video game inspired by Indian mythology and in-game architecture influenced by medieval Rajasthan. #Videogame #Nintendo',
        link: 'https://www.youtube.com/watch?v=CLNcqL__tPQ&t=11s',
        image: image6
    }
];
