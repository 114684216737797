import React from 'react';
import '../styles/About.css';
import leftImage from '../assets/murtho.png'; 
import 'bootstrap/dist/css/bootstrap.min.css';


const About = () => {
    return (
        <section id="about" className="about section container-fluid d-flex align-items-center vh-100">
            <div className="row w-100">
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <img src={leftImage} alt="About" className="img-fluid" />
                </div>
                <div className="col-md-6 text-white d-flex flex-column justify-content-center text-container">
                    <h2 className="text-center">BEHIND THIS UNIVERSE</h2>
                    <p className="about-text">Nadabramha Records is the brainchild of sound practitioner and ethnomusicological researcher, MSR Murthy aka Murthovic. One of the initiators of the electronic music subculture in India, with associations and experiments spanning over two decades, Murthovic has professed his multi-disciplinary philosophy of sound through radio shows, live acts, audio-visual IPs and multimedia productions across genres and arenas.</p>
                    <p className="about-text">Nadabramha brings you the expertise for all audio and experiential design solutions—including films, digital campaigns and TVCs, video games, documentaries, sound design and score, workshops, audio consultation, and artist curation for live performances and sessions. Having grazed in the fields of conservation, education, entertainment, fashion, gaming, hospitality, and many others through our work, Nadabramha truly believes there is music everywhere if you listen to the universe.</p>
                </div>
            </div>
        </section>
    );
};

export default About;