import React from 'react';
import '../styles/SubHome.css';
import leftImage from '../assets/home2img.png';
import rightImage from '../assets/subhomeright.png';

const SubHome = () => {
    return (
        <section id="sub-home" className="sub-home section">
            <div className="sub-home-content">
                <div className="image-left">
                    <img src={leftImage} alt="Left" />
                </div>
                <div className="image-right">
                    <img src={rightImage} alt="Right" />
                </div>
                <div className="text-content">
                    <p>
                        FILMS, CAMPAIGNS & TVCS, VIDEO GAMES, DOCUMENTARIES, SOUND DESIGN & SCORE,
                        INDEPENDENT PROJECTS, WORKSHOPS, FIELD CONSULTATION, ARTIST CURATION & LIVE
                        PERFORMANCES. YOUR SEARCH FOR END-TO-END AUDIO PROJECT MANAGEMENT ENDS HERE.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default SubHome;