import React from 'react';
import '../styles/Home.css';
import centerImage from '../assets/earwaves.jpeg';

const Home = () => {
    return (
        <section id="home" className="home section container-fluid d-flex align-items-center">
            <div className="scrolling-text-container">
                <div className="scrolling-text left">
                    <p>LISTEN TO THE UNIVERSE</p>
                </div>
                <div className="scrolling-text right">
                    <p>LISTEN TO THE UNIVERSE</p>
                </div>
            </div>
            <div className="row w-100">
                <div className="col-12 text-center">
                    <div className="home-content">
                        <h1 className="display-1">NADABRAMHA</h1>
                        <img src={centerImage} alt="Center" className="center-image img-fluid"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home;
