import React from 'react';
import '../styles/Contact.css';

const Contact = () => {
    return (
        <section id="contact" className="contact section">
            <h2>LOOKING FOR SOUND JUDGEMENT?</h2>
            <p>Talk to us immediately.</p>
            <p>Phone: +91 9866015750</p>
            <p>Email: nadabramharecords@gmail.com</p>
            <div className="social-icons">
                <a href="#"><i className="fa fa-facebook"></i></a>
                <a href="#"><i className="fa fa-twitter"></i></a>
                <a href="#"><i className="fa fa-instagram"></i></a>
            </div>
        </section>
    );
};

export default Contact;
