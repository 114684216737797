import React from 'react';
import { projects } from '../data';
import '../styles/Projects.css';

const Projects = () => {
    return (
        <section id="projects" className="projects section">
            <h2>PROJECTS</h2>
            <div className="projects-container">
                {projects.map((project, index) => (
                    <div key={index} className="project">
                        <img src={project.image} alt={project.title} />
                        <h3>{project.title}</h3>
                        <p>{project.description}</p>
                        <a href={project.link} target="_blank" rel="noopener noreferrer">Learn More</a>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Projects;
