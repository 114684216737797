import React from 'react';
import Home from './Home';
import SubHome from './SubHome';
import Projects from './Projects';
import VisualMedia from './VisualMedia';
import About from './About';
import Contact from './Contact';
import '../styles/MainPage.css';

const MainPage = () => {
    return (
        <div className="main-page">
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item mx-2">
                                <a className="nav-link nav-link-large" href="#home">Home</a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link nav-link-large" href="#sub-home">Sub Home</a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link nav-link-large" href="#projects">Projects</a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link nav-link-large" href="#visual-media">Visual Media</a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link nav-link-large" href="#about">About</a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link nav-link-large" href="#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="content">
                <Home />
                <hr className='divider' />
                <SubHome />
                <hr className='divider' />
                <Projects />
                <hr className='divider' />
                <VisualMedia />
                <hr className='divider' />
                <About />
                <hr className='divider' />
                <Contact />
            </div>
        </div>
    );
};

export default MainPage;
