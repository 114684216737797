import React from 'react';
import '../styles/VisualMedia.css';

const VisualMedia = () => {
    return (
        <section id="visual-media" className="visual-media section">
            <h2>VISUAL MEDIA</h2>
            <div className="content">
                <p>Content for Visual Media</p>
            </div>
        </section>
    );
};

export default VisualMedia;
